code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* src/index.css */
body {
  margin: 0;
  padding: 0;
  background-image: url('/public/img.png'); /* Путь к вашему изображению */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white; /* Белый цвет текста по умолчанию */
}

#root {
  min-height: 100vh;
}

/* Опционально: стили для контента, если нужно */
.container {
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон для контейнера */
  color: white; /* Белый цвет текста внутри контейнера */
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto; /* Для того чтобы футер всегда был внизу */
  padding: 20px;
  background-color: #333;
  color: #fff;
}

.download-button img {
  display: block;
  width: 150px;
  height: auto;
}

/* src/index.css или ваш CSS-файл */
.fixed-download-button {
  position: fixed;
  bottom: 5px; /* Отступ от нижнего края */
  right: 20px;  /* Отступ от правого края */
  z-index: 1000; /* Убедитесь, что кнопка находится поверх другого контента */
  user-select: none;
}

/* src/pages/Home.css */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  color: #333;
  text-align: center;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  list-style-type: none;
}

.home-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.home-description {
  font-size: 1.2rem;
  max-width: 600px;
  line-height: 1.6;
  color: #fff;
}

/* src/pages/Reviews.css */
.reviews-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  color: #333;
  text-align: center;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.reviews-title {
  font-size: 1.5rem; /* Меньший размер шрифта для мобильных устройств */
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
}

.review-card {
  background: #555;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px; /* Уменьшенный отступ для мобильных устройств */
  margin: 10px;
  max-width: 100%; /* Ширина карточек адаптируется под ширину экрана */
  width: 100%;
  box-sizing: border-box; /* Учитывает padding и border в ширину элемента */
}

.review-text {
  font-size: 1rem; /* Уменьшенный размер шрифта для мобильных устройств */
  margin: 0;
  color: #fff;
}

.review-author {
  font-size: 0.9rem; /* Уменьшенный размер шрифта для мобильных устройств */
  margin-top: 10px;
  color: #999;
  font-style: italic;
}

@media (min-width: 600px) {
  .reviews-title {
    font-size: 2rem; /* Размер шрифта для больших экранов */
  }

  .review-card {
    padding: 20px;
    max-width: 500px; /* Максимальная ширина карточек для больших экранов */
  }

  .review-text {
    font-size: 1.1rem;
  }

  .review-author {
    font-size: 1rem;
  }
}